<template>
  <articles-conversions-report-info-table-row v-if="index === 0" />
  <tr v-else>
    <td>
      <v-layout row justify-space-between>
        <v-flex xs8>
          {{ article.url }}
        </v-flex>
        <v-flex style="text-align: end;">
          <v-btn
            icon
            small
            :to="{ name: 'articles.show', params: { articleId: article.id } }"
          >
            <v-icon>far fa-fw fa-search</v-icon>
          </v-btn>
          <v-btn icon small :href="`//${article.url}`" target="_blank">
            <v-icon>far fa-fw fa-globe</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </td>
    <td class="metrics article-row">{{ article.uniquePageviews }}</td>
    <td class="metrics article-row">{{ article.totalConversions }}</td>
    <td class="metrics article-row">
      {{ round(article.totalConversionRate) }}%
    </td>
    <td class="metrics article-row">{{ article.directConversions }}</td>
    <td class="metrics article-row">
      {{ round(article.directConversionRate) }}%
    </td>
    <td class="metrics">{{ article.uniqueLandingSessions }}</td>
    <td class="metrics">{{ article.landingConversions }}</td>
    <td class="metrics">{{ round(article.landingConversionRate) }}%</td>
    <td class="metrics">{{ round(article.bounceRate) }}%</td>
    <td class="metrics">{{ time(article.timeOnPage) }}</td>
  </tr>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { isNil } from "lodash";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";
import ArticlesConversionsReportInfoTableRow from "@/components/statistics/articles/ArticlesConversionsReportInfoTableRow";

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const { mapGetters: mapWebsiteGetters } = createNamespacedHelpers(
  "articles-conversions-report"
);

export default {
  name: "articles-conversions-report-table-row",
  components: { ArticlesConversionsReportInfoTableRow },
  props: {
    articleId: {
      type: Number,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapWebsiteGetters([FIND_BY_ID]),
    article() {
      return this[FIND_BY_ID](this.articleId);
    }
  },
  methods: {
    round(rate) {
      return isNil(rate) ? rate : rate.toFixed(2);
    },
    time(time) {
      if (isNil(time)) {
        return null;
      }
      let date = new Date(null);
      date.setSeconds(time.toString());
      return date.toISOString().substr(11, 8);
    }
  }
};
</script>
<style scoped>
.metrics {
  text-align: right;
}
.article-row {
  background-color: #f2f1f3;
}
</style>
