<template>
  <v-autocomplete
    v-model="model"
    v-validate="internalValidationRules"
    :name="internalName"
    :data-vv-name="internalName"
    :data-vv-as="label"
    :error-messages="internalErrorMessages"
    :items="items"
    :label="internalLabel"
    :item-text="itemText"
    :item-value="itemValue"
    :readonly="isReadOnly"
    :disabled="isDisabled"
    :multiple="isMultiple"
    :clearable="clearable"
    :loading="isLoading"
    :search-input.sync="search"
    :chips="isMultiple"
    persistent-hint
    :browser-autocomplete="browserAutocomplete"
    @change="onChange"
  >
    <template v-slot:selection="{ item, selected }" v-if="isMultiple">
      <v-chip :selected="selected" small label>
        <span v-text="item.name"></span>
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script type="text/babel">
import VAutocompleteMixin from "@/components/mixins/input/VAutocompleteMixin";

export default {
  name: "v-autocomplete-language",
  inject: ["$validator"],
  mixins: [VAutocompleteMixin],
  data: () => ({
    store: "language-select",
    defaultLabel: "Language",
    defaultLabelMultiple: "Languages"
  })
};
</script>
