<template>
  <table-overview
    title="Articles Conversion Report"
    icon="fas fa-chart-bar"
    store="articles-conversions-report"
    :headers="headers"
    class="articles-submissions__table"
    force-initial-sort="total_conversions"
    descending
    sticky-first-column
    sticky-thead
    searchable
  >
    <articles-conversions-report-table-row
      slot="table-row"
      slot-scope="{ item, index }"
      :article-id="item"
      :index="index"
    />
    <articles-conversions-report-table-filters
      slot="filters"
      store="articles-conversions-report"
    />
  </table-overview>
</template>
<script type="text/babel">
import TableOverview from "../../../components/generic/overview/TableOverview";
import articlesConversionsReportModule from "@/store/modules/articles-conversions-report";
import { mapOverviewLifecycleMethods } from "@/views/helpers";
import { createNamespacedHelpers as createNamespacedHelpersFromVuex } from "vuex";
import { createNamespacedHelpers } from "@/store/helpers";
import ArticlesConversionsReportTableRow from "@/components/statistics/articles/ArticlesConversionsReportTableRow";
import ArticlesConversionsReportTableFilters from "@/components/statistics/articles/ArticlesConversionsReportTableFilter";

const { mapGetters } = createNamespacedHelpersFromVuex(
  "articles-conversions-report"
);
const { mapComputedFilters } = createNamespacedHelpers(
  "articles-conversions-report"
);

const overviewLifecycleMethods = mapOverviewLifecycleMethods(
  "articles-conversions-report",
  articlesConversionsReportModule
);

export default {
  name: "articles-conversions-report-view",
  components: {
    ArticlesConversionsReportTableFilters,
    ArticlesConversionsReportTableRow,
    TableOverview
  },
  computed: {
    ...mapComputedFilters(["from", "to", "by"]),
    ...mapGetters([]),
    headers() {
      return [
        { text: "Article url", value: "url", class: "table-header-acr" },
        {
          text: "Unique Pageviews",
          value: "unique_pageviews",
          class: "table-header-acr",
          info: "Aantal unieke paginabezoeken"
        },
        {
          text: "Total Conversions",
          value: "total_conversions",
          class: "table-header-acr",
          info:
            "Zowel de landing als direct submissions die via dit artikel gegenereerd werden. Geeft een globaalbeeld over de prestaties van dit artikel."
        },
        {
          text: "Total Conversion Rate",
          value: "total_conversion_rate",
          class: "table-header-acr",
          info:
            "(totale conversies / Unieke paginabezoeken) * 100 = totale conversie. Geeft een globaalbeeld over de prestaties van dit artikel."
        },
        {
          text: "Direct Conversions",
          value: "direct_conversions",
          class: "table-header-acr",
          info:
            "Aantal submissions die op deze pagina of de daaropvolgende /offerte pagina gegenereerd werden. Deze artikels zijn dus rechtstreeks de aanzet geweest om een offerte aan te vragen."
        },
        {
          text: "Direct Conversion Rate",
          value: "direct_conversion_rate",
          class: "table-header-acr",
          info:
            "(directe conversies / Unieke paginabezoeken) * 100 = In vergelijking met totale conversie toont deze statistiek aan of het artikel een directe of indirecte impact heeft op conversies."
        },
        {
          text: "Unique Landing Sessions",
          value: "unique_landing_sessions",
          class: "table-header-acr",
          info:
            "Aantal unieke paginabezoeken waarbij dit artikel de landingspagina was."
        },
        {
          text: "Landing conversions",
          value: "landing_conversions",
          class: "table-header-acr",
          info:
            "Aantal submissions die binnengekomen zijn waarbij dit artikel de eerste pagina (landingspage) was van de bezoekers."
        },
        {
          text: "Landing Conversion Rate",
          value: "landing_conversion_rate",
          class: "table-header-acr",
          info:
            "(landing conversions / Unique landing sessions) * 100 = % van het aantal landingspage bezoekers die uiteindelijk converteren."
        },
        {
          text: "Bounce Rate",
          value: "bounce_rate",
          class: "table-header-acr",
          info:
            "Het percentage van bezoekers die enkel deze pagina bezoeken en weg gaat zonder conversie of verder browsen op de website."
        },
        {
          text: "Time On Page",
          value: "time_on_page",
          class: "table-header-acr"
        }
      ];
    }
  },
  ...overviewLifecycleMethods
};
</script>
<style lang="scss">
.table-header-acr {
  color: white !important;
  background-color: #2e6fb4 !important;
}
</style>
