<template>
  <tr>
    <td></td>
    <td colspan="5" class="article">Prestatie als artikel</td>
    <td colspan="5" class="landing">Prestatie als landingspagina</td>
  </tr>
</template>
<script type="text/babel">
export default {
  name: "articles-conversions-report-info-table-row"
};
</script>
<style scoped>
.article {
  background-color: #ffcd00 !important;
  text-align: center;
}
.landing {
  background-color: #ffe169 !important;
  text-align: center;
}
</style>
