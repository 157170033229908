var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.internalValidationRules),expression:"internalValidationRules"}],attrs:{"name":_vm.internalName,"data-vv-name":_vm.internalName,"data-vv-as":_vm.label,"error-messages":_vm.internalErrorMessages,"items":_vm.items,"label":_vm.internalLabel,"item-text":_vm.itemText,"item-value":_vm.itemValue,"readonly":_vm.isReadOnly,"disabled":_vm.isDisabled,"multiple":_vm.isMultiple,"clearable":_vm.clearable,"loading":_vm.isLoading,"search-input":_vm.search,"no-filter":_vm.isAsync,"chips":_vm.isMultiple,"return-object":_vm.returnObject,"persistent-hint":"","browser-autocomplete":_vm.browserAutocomplete},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":_vm.onChange},scopedSlots:_vm._u([(_vm.isMultiple)?{key:"selection",fn:function(ref){
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',{attrs:{"selected":selected,"small":"","label":""}},[_c('span',{domProps:{"textContent":_vm._s(item.name)}})])]}}:null,{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-tile-content',[_c('v-list-tile-title',{domProps:{"innerHTML":_vm._s(
          ((_vm.addSearchHighlightMarkup(
            item.postalCode
          )) + " - " + (_vm.addSearchHighlightMarkup(item.name)))
        )}})],1)]}}],null,true),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})}
var staticRenderFns = []

export { render, staticRenderFns }