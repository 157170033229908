<template>
  <v-card class="ma-3 pa-3">
    <div style="text-align: center;">
      <h1>Subjects Sales Report</h1>
      <p class="date">{{ dateInput.from }} - {{ dateInput.to }}</p>
    </div>
    <v-layout row>
      <v-flex xs6 class="ma-3 pa-3">
        <gmap-map
          ref="mapRef"
          :center="{ lat: 50.477620774049164, lng: 4.484564937499954 }"
          :zoom="8"
          :options="mapOptions"
          :draggable="false"
          :clickable="false"
          :zoomable="false"
          map-type-id="roadmap"
          style="width: 100%; height: 100%"
        >
          <gmap-circle
            v-if="city && radius"
            :center="{ lat: parseFloat(city.lat), lng: parseFloat(city.lng) }"
            :radius="parseInt(radius * 1000)"
            :editable="false"
            :options="shapeOptions"
          />
          <gmap-polygon
            v-for="(bound, index) in provincesBounds"
            :key="`province-${index}`"
            :path="prepBounds(bound)"
            :options="excludedPolygonsOptions"
          />
        </gmap-map>
      </v-flex>
      <v-flex xs6>
        <v-card class="ma-3 pa-3">
          <v-flex xs12>
            <v-autocomplete-subject v-model="subjects" multiple required />
          </v-flex>
          <v-layout row>
            <v-flex md4>
              <v-btn
                block
                :color="locationType === 'RADIUS' ? 'primary' : undefined"
                @click="setLocationType('RADIUS')"
              >
                <v-icon small>fad fa-circle</v-icon>&nbsp; RADIUS
              </v-btn>
            </v-flex>
            <v-flex md4>
              <v-btn
                block
                :color="locationType === 'PROVINCE' ? 'primary' : undefined"
                @click.native="setLocationType('PROVINCE')"
              >
                <v-icon small>fad fa-draw-polygon</v-icon>&nbsp; PROVINCE
              </v-btn>
            </v-flex>
            <v-flex md4>
              <v-btn
                block
                :color="locationType === 'COUNTRY' ? 'primary' : undefined"
                @click="setLocationType('COUNTRY')"
              >
                <v-icon small>fad fa-flag</v-icon>&nbsp; COUNTRY
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout class="pa-3" row>
            <v-icon class="mr-4">{{
              showCityWarning
                ? "fas fa-map-marker-alt-slash"
                : "fas fa-map-marker-alt"
            }}</v-icon>
            <div v-if="locationType === 'RADIUS'">
              <v-layout row>
                <v-flex class="pa-0" xs8>
                  <v-autocomplete-city v-model="city" return-object />
                </v-flex>
                <v-icon class="ml-4 mr-2">far fa-drafting-compass</v-icon>
                <v-flex class="pa-0" xs4>
                  <v-text-field
                    v-model="radius"
                    label="Radius"
                    placeholder="Radius in km..."
                  />
                </v-flex>
              </v-layout>
            </div>
            <div v-if="locationType === 'PROVINCE'">
              <v-layout row>
                <v-flex class="pa-0 ml-2" xs12>
                  <v-autocomplete-province
                    v-model="provinces"
                    multiple
                    return-object
                  />
                </v-flex>
              </v-layout>
            </div>
            <div v-if="locationType === 'COUNTRY'">
              <v-flex class="pa-0" xs12>
                <v-autocomplete-country v-model="country" />
              </v-flex>
            </div>
          </v-layout>
          <v-subheader v-if="showCityWarning" class="warning-no-coordinates"
            >We currently do not have coordinates for this city. Please select a
            different city to use the location filter</v-subheader
          >
          <v-subheader v-if="showRadiusWarning" class="warning-no-coordinates"
            >If you want to filter on location you need to specify a city and a
            radius.</v-subheader
          >
          <v-layout class="pa-3" row>
            <v-icon class="mr-2">fad fa-globe-europe</v-icon>
            <v-flex class="ml-2 pa-0" xs6>
              <v-autocomplete-language v-model="language" />
            </v-flex>
          </v-layout>
          <v-flex xs12>
            <between-date-input v-model="dateInput" required />
          </v-flex>
          <v-btn block color="secondary" dark @click="fetch"
            >Generate Stats</v-btn
          >
        </v-card>
      </v-flex>
    </v-layout>
  </v-card>
</template>
<script type="text/babel">
import { isNil } from "lodash";
import VAutocompleteCity from "../../../components/filter/VAutocompleteCity";
import VAutocompleteLanguage from "../../../components/language/VAutocompleteLanguage";
import BetweenDateInput from "../../../components/generic/input/dates/BetweenDateInput";
import VAutocompleteSubject from "../../../components/subject/VAutocompleteSubject";
import moment from "moment";
import VAutocompleteCountry from "../../../components/country/VAutocompleteCountry";
import VAutocompleteProvince from "../../../components/filter/VAutocompleteProvince";
import { gmapApi } from "vue2-google-maps";

export default {
  name: "subject-sales-performance-report-filters",
  inject: ["$validator"],
  components: {
    VAutocompleteProvince,
    VAutocompleteCountry,
    VAutocompleteSubject,
    BetweenDateInput,
    VAutocompleteLanguage,
    VAutocompleteCity
  },
  data() {
    return {
      city: null,
      radius: null,
      language: null,
      country: 1,
      provinces: [],
      subjects: [],
      provincesBounds: [],
      locationType: "COUNTRY",
      dateInput: {
        from: moment()
          .subtract(1, "years")
          .startOf("month")
          .format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD")
      },
      showCityWarning: false,
      showRadiusWarning: false,
      shapeOptions: {
        strokeColor: "#FF0000",
        strokeOpacity: "0.8",
        strokeWeight: 0,
        fillColor: "#2E6FB4",
        fillOpacity: "0.35"
      },
      excludedPolygonsOptions: {
        strokeColor: "#FF0000",
        strokeOpacity: "0.8",
        strokeWeight: 0,
        fillColor: "#2E6FB4",
        fillOpacity: "0.35"
      },
      mapOptions: {
        disableDoubleClickZoom: true,
        scrollWheel: true,
        disableDefaultUI: true,
        styles: [
          {
            featureType: "landscape",
            stylers: [
              {
                hue: "#FFAD00"
              },
              {
                saturation: 50.2
              },
              {
                lightness: -34.8
              },
              {
                gamma: 1
              }
            ]
          },
          {
            featureType: "road.highway",
            stylers: [
              {
                hue: "#FFAD00"
              },
              {
                saturation: -19.8
              },
              {
                lightness: -1.8
              },
              {
                gamma: 1
              }
            ]
          },
          {
            featureType: "road.arterial",
            stylers: [
              {
                hue: "#FFAD00"
              },
              {
                saturation: 72.4
              },
              {
                lightness: -32.6
              },
              {
                gamma: 1
              }
            ]
          },
          {
            featureType: "road.local",
            stylers: [
              {
                hue: "#FFAD00"
              },
              {
                saturation: 74.4
              },
              {
                lightness: -18
              },
              {
                gamma: 1
              }
            ]
          },
          {
            featureType: "water",
            stylers: [
              {
                hue: "#00FFA6"
              },
              {
                saturation: -63.2
              },
              {
                lightness: 38
              },
              {
                gamma: 1
              }
            ]
          },
          {
            featureType: "poi",
            stylers: [
              {
                hue: "#FFC300"
              },
              {
                saturation: 54.2
              },
              {
                lightness: -14.4
              },
              {
                gamma: 1
              }
            ]
          }
        ]
      }
    };
  },
  watch: {
    city: {
      handler(newVal) {
        if (!isNil(newVal)) {
          this.showCityWarning = isNil(newVal.lat) || isNil(newVal.lng);
          this.provinces = [];
          this.country = null;
        }
      },
      deep: true
    },
    provinces: {
      handler(newVal) {
        let bounds = [];
        newVal.forEach(province => {
          province.bounds.forEach(bound => {
            bounds.push(bound);
          });
        });
        this.provincesBounds = bounds;
        if (!isNil(newVal) && newVal.length > 0) {
          this.city = null;
          this.radius = null;
          this.country = null;
        }
      },
      deep: true
    },
    country(newVal) {
      if (!isNil(newVal)) {
        this.provinces = [];
        this.city = null;
        this.radius = null;
      }
    }
  },
  computed: {
    google: gmapApi
  },
  methods: {
    async fetch() {
      const isValid = await this.$validator.validate();
      if (!isValid) {
        return;
      }
      if (isNil(this.radius) && !isNil(this.city)) {
        this.showRadiusWarning = true;
        return;
      }
      this.showRadiusWarning = false;
      this.showCityWarning = false;
      this.$emit("fetch", {
        location: !isNil(this.city)
          ? {
              lat: this.city.lat,
              lng: this.city.lng,
              radius: this.radius * 1000
            }
          : null,
        language: this.language,
        provinces: isNil(this.provinces) ? null : this.provinces.map(p => p.id),
        country: this.country,
        subjects: this.subjects,
        date: this.dateInput
      });
    },
    prepBounds(bounds) {
      return bounds.map(
        coords => new this.google.maps.LatLng(coords[0], coords[1])
      );
    },
    setLocationType(locationType) {
      this.locationType =
        locationType === this.locationType ? null : locationType;
    }
  }
};
</script>
<style scoped>
.warning-no-coordinates {
  color: red;
  height: 0px;
  font-size: 12px;
}
.button-group-buttons {
  color: white !important;
  background-color: #2e6fb4 !important;
}
</style>
