<template>
  <div v-if="loading" class="text-xs-center">
    <v-progress-circular
      :size="50"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
  <v-card
    v-else-if="visible"
    style="background-color: white; margin-top: 20px;"
  >
    <v-container>
      <div style="height: 350px">
        <vue-apex-charts
          ref="monthlySubmissionsChart"
          height="100%"
          type="pie"
          :options="options"
          :series="series"
        />
      </div>
    </v-container>
  </v-card>
</template>
<script type="text/babel">
import vueApexCharts from "vue-apexcharts";
import moment from "moment";
import api from "@/api";
import { isNil } from "lodash";

const fetchExclusiveInterestCall = api.subjectsStats.fetchExclusiveInterest;

export default {
  name: "subject-exclusive-interest",
  props: {
    subject: {
      type: Number,
      required: false
    },
    select: {
      type: Object,
      required: false
    }
  },
  components: {
    vueApexCharts
  },
  data() {
    return {
      country: null,
      language: null,
      loading: false,
      visible: false,
      options: {
        chart: {
          width: 380,
          type: "pie"
        },
        colors: ["#2E6FB4", "#FFCD00"],
        labels: ["Exclusive", "Mixed"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ]
      },
      series: []
    };
  },
  watch: {
    select(newVal) {
      if (newVal !== {} && !isNil(this.subject)) {
        this.country = newVal.country;
        this.language = newVal.language;
        this.fetch(this.subject);
      }
    },
    subject(newVal) {
      if (!isNil(this.country) && !isNil(newVal)) {
        this.fetch(newVal);
      }
    }
  },
  computed: {},
  methods: {
    async fetch(subjectId) {
      this.loading = true;
      this.visible = false;
      this.series = [];
      const now = moment().format("YYYY-MM-DD");
      const oneYearAgo = moment()
        .subtract(1, "years")
        .startOf("month")
        .format("YYYY-MM-DD");

      const data = await fetchExclusiveInterestCall({
        subjects: [subjectId],
        language: this.language,
        country: this.country,
        date: { from: oneYearAgo, to: now }
      });
      this.loading = false;
      this.visible = true;
      this.series.push(data.exclusive);
      this.series.push(data.total - data.exclusive);
    }
  }
};
</script>
