<template>
  <v-data-table
    v-if="visible"
    :headers="headers"
    :items="items"
    :loading="loading"
    :pagination.sync="pagination"
    :rows-per-page-items="[10, 20, 50, 100]"
    class="elevation-1"
  >
    <template v-slot:headers="props">
      <th
        v-for="header in props.headers"
        :key="header.text"
        :class="[
          'column sortable',
          pagination.descending ? 'desc' : 'asc',
          header.value === pagination.sortBy ? 'active' : ''
        ]"
        @click="changeSort(header.value)"
      >
        <v-tooltip v-if="hasInfoText(header.info)" bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              {{ header.text }}
            </span>
          </template>
          <span>
            {{ header.info }}
          </span>
        </v-tooltip>
        <span v-else>
          {{ header.text }}
        </span>
      </th>
    </template>
    <v-progress-linear
      v-slot:progress
      color="primary"
      indeterminate
    ></v-progress-linear>
    <template v-slot:items="props">
      <td v-if="hasRolesOrPermissions('article_view')" class="v-table-td--icon">
        <v-btn
          icon
          target="_blank"
          :to="{
            name: 'articles.show',
            params: { articleId: props.item.id }
          }"
        >
          <v-icon>far fa-fw fa-search</v-icon>
        </v-btn>
      </td>
      <td>{{ props.item.url }}</td>
      <td class="text-xs-right">{{ props.item.pageviews }}</td>
      <td class="text-xs-right">
        {{ round(props.item.totalConversionRate) }}%
      </td>
      <td class="text-xs-right">{{ props.item.totalConversions }}</td>
      <td class="text-xs-right">{{ props.item.totalSubjectConversions }}</td>
      <td class="text-xs-right">
        {{ round(props.item.subjectConversionShare) }}%
      </td>
      <td class="text-xs-right" style="background-color: #E2E2E2">
        {{ props.item.subjectConversions }}
      </td>
    </template>
  </v-data-table>
</template>
<script type="text/babel">
import moment from "moment";
import api from "@/api";
import { isNil } from "lodash";
import { createNamespacedHelpers } from "vuex";
const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const fetchSubjectMapCall = api.subjectsStats.fetchArticleMap;

export default {
  name: "subject-article-map",
  ref: "foo",
  props: {
    subject: {
      type: Number,
      required: false
    },
    select: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      country: null,
      language: null,
      visible: false,
      loading: false,
      items: [],
      headers: [
        { text: "", value: "", sortable: false },
        { text: "Url", value: "url" },
        { text: "Pageviews", value: "pageviews" },
        { text: "Total Conversion Rate", value: "totalConversionRate" },
        { text: "Total Conversions", value: "totalConversions" },
        { text: "Subject Total Conversions", value: "totalSubjectConversions" },
        { text: "Subject Conversion Share", value: "subjectConversionShare" },
        {
          text: "Subject locale Conversions",
          value: "subjectConversions",
          info:
            "Total submissions with the selected Subject in combination with the selected locale of the form"
        }
      ],
      pagination: {
        sortBy: "subjectConversions",
        descending: true
      }
    };
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"])
  },
  watch: {
    select(newVal) {
      if (newVal !== {} && !isNil(this.subject)) {
        this.country = newVal.country;
        this.language = newVal.language;
        this.fetch(this.subject);
      }
    },
    subject(newVal) {
      if (!isNil(this.country) && !isNil(newVal)) {
        this.fetch(newVal);
      }
    }
  },
  methods: {
    async fetch(subjectId) {
      this.items = [];
      this.loading = true;
      this.visible = true;
      const now = moment().format("YYYY-MM-DD");
      const oneYearAgo = moment()
        .subtract(1, "years")
        .startOf("month")
        .format("YYYY-MM-DD");

      this.items = await fetchSubjectMapCall({
        subjects: [subjectId],
        language: this.language,
        country: this.country,
        date: { from: oneYearAgo, to: now }
      });
      this.loading = false;
    },
    round(rate) {
      return isNil(rate) ? null : rate.toFixed(2);
    },
    hasInfoText(data) {
      return !isNil(data);
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    }
  }
};
</script>
