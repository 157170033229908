<template>
  <div v-if="loading" class="text-xs-center">
    <v-progress-circular
      :size="50"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
  <v-card
    v-else-if="visible"
    style="background-color: white; margin-top: 20px;"
  >
    <v-container>
      <div :style="`height: ${height}px`">
        <vue-apex-charts
          ref="monthlySubmissionsChart"
          height="100%"
          type="bar"
          :options="options"
          :series="series"
        />
      </div>
    </v-container>
  </v-card>
</template>
<script type="text/babel">
import vueApexCharts from "vue-apexcharts";
import moment from "moment";
import api from "@/api";
import { isNil } from "lodash";

const fetchSubjectMapCall = api.subjectsStats.fetchInterestMap;

export default {
  name: "subject-interest-map",
  props: {
    subject: {
      type: Number,
      required: false
    },
    select: {
      type: Object,
      required: false
    }
  },
  components: {
    vueApexCharts
  },
  data() {
    return {
      country: null,
      language: null,
      loading: false,
      visible: false,
      options: {
        chart: {
          toolbar: {
            show: false
          },
          height: 350,
          type: "bar"
        },
        title: {
          text: "Subject Interest",
          align: "center",
          margin: 30,
          offsetY: 20,
          floating: true,
          style: {
            fontSize: "16px"
          }
        },
        colors: ["#2E6FB4"],
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: []
        }
      },
      series: [
        {
          name: "Submissions",
          data: []
        }
      ]
    };
  },
  watch: {
    select(newVal) {
      if (newVal !== {} && !isNil(this.subject)) {
        this.country = newVal.country;
        this.language = newVal.language;
        this.fetch(this.subject);
      }
    },
    subject(newVal) {
      if (!isNil(this.country) && !isNil(newVal)) {
        this.fetch(newVal);
      }
    }
  },
  computed: {
    height() {
      if (this.options.xaxis.categories.length === 1) {
        return 150;
      } else if (this.options.xaxis.categories.length < 4) {
        return 250;
      } else {
        return 350;
      }
    }
  },
  methods: {
    async fetch(subjectId) {
      this.loading = true;
      this.visible = false;
      this.options.xaxis.categories = [];
      this.series[0].data = [];
      const now = moment().format("YYYY-MM-DD");
      const oneYearAgo = moment()
        .subtract(1, "years")
        .startOf("month")
        .format("YYYY-MM-DD");

      const subjectMap = await fetchSubjectMapCall({
        subjects: [subjectId],
        language: this.language,
        country: this.country,
        date: { from: oneYearAgo, to: now }
      });
      this.loading = false;
      this.visible = true;
      subjectMap.forEach(subject => {
        this.series[0].data.push(subject.submissionCount);
        this.options.xaxis.categories.push(subject.subjectName);
      });
    }
  }
};
</script>
