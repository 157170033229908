var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.visible)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"pagination":_vm.pagination,"rows-per-page-items":[10, 20, 50, 100]},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"headers",fn:function(props){return _vm._l((props.headers),function(header){return _c('th',{key:header.text,class:[
        'column sortable',
        _vm.pagination.descending ? 'desc' : 'asc',
        header.value === _vm.pagination.sortBy ? 'active' : ''
      ],on:{"click":function($event){return _vm.changeSort(header.value)}}},[(_vm.hasInfoText(header.info))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(header.text)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(header.info)+" ")])]):_c('span',[_vm._v(" "+_vm._s(header.text)+" ")])],1)})}},{key:"items",fn:function(props){return [(_vm.hasRolesOrPermissions('article_view'))?_c('td',{staticClass:"v-table-td--icon"},[_c('v-btn',{attrs:{"icon":"","target":"_blank","to":{
          name: 'articles.show',
          params: { articleId: props.item.id }
        }}},[_c('v-icon',[_vm._v("far fa-fw fa-search")])],1)],1):_vm._e(),_c('td',[_vm._v(_vm._s(props.item.url))]),_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(props.item.pageviews))]),_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(_vm.round(props.item.totalConversionRate))+"% ")]),_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(props.item.totalConversions))]),_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(props.item.totalSubjectConversions))]),_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(_vm.round(props.item.subjectConversionShare))+"% ")]),_c('td',{staticClass:"text-xs-right",staticStyle:{"background-color":"#E2E2E2"}},[_vm._v(" "+_vm._s(props.item.subjectConversions)+" ")])]}}],null,false,108307518)},[_c('v-progress-linear',{attrs:{"color":"primary","indeterminate":""},scopedSlots:_vm._u([{key:"progress",fn:function(){return undefined},proxy:true}],null,false,63074868)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }