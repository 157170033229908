<template>
  <v-card v-if="visible" style="background-color: white; margin-top: 20px;">
    <div style="height: 350px">
      <vue-apex-charts
        ref="totalWebsiteMap"
        height="100%"
        type="bar"
        :options="options"
        :series="series"
      />
    </div>
    <div v-if="monthlySeries.length > 0" style="height: 200px">
      <vue-apex-charts
        ref="monthlyWebsiteMap"
        height="100%"
        type="line"
        :options="monthlyOptions"
        :series="monthlySeries"
      />
    </div>
  </v-card>
</template>
<script type="text/babel">
import vueApexCharts from "vue-apexcharts";
import moment from "moment";
import api from "@/api";
import { isNil, sum } from "lodash";

const fetchSubjectMapCall = api.subjectsStats.fetchWebsiteMap;

export default {
  name: "subject-website-map",
  ref: "foo",
  props: {
    subject: {
      type: Number,
      required: false
    },
    select: {
      type: Object,
      required: false
    }
  },
  components: {
    vueApexCharts
  },
  data() {
    return {
      country: null,
      language: null,
      visible: false,
      websiteMap: {},
      options: {
        chart: {
          toolbar: {
            show: false
          },
          height: 350,
          type: "bar",
          events: {
            dataPointSelection: (e, chart, opts) => {
              this.addSeriesToMonthly(opts.dataPointIndex);
            }
          }
        },
        title: {
          text: "Website Map",
          align: "center",
          margin: 30,
          offsetY: 20,
          floating: true,
          style: {
            fontSize: "16px"
          }
        },
        colors: [
          "#00d904",
          "#89bf00",
          "#d9d900",
          "#db9d00",
          "#db5400",
          "#999999",
          "#999998",
          "#999997",
          "#999996",
          "#999995",
          "#999994",
          "#999993",
          "#999992",
          "#999991",
          "#939393",
          "#939394",
          "#939395",
          "#939396",
          "#939397",
          "#939398",
          "#939399",
          "#949399",
          "#959399",
          "#969399",
          "#979399",
          "#989399",
          "#999399"
        ],
        plotOptions: {
          bar: {
            horizontal: true,
            distributed: true
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          labels: {
            show: true,
            minWidth: 0,
            maxWidth: 500
          }
        }
      },
      series: [
        {
          name: "Submissions",
          data: []
        }
      ],
      monthlyOptions: {
        chart: {
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          background: "#f5f5f5"
        },
        stroke: {
          width: 2
        },
        title: {
          text: this.title,
          align: "center",
          margin: 30,
          offsetY: 20,
          floating: true,
          style: {
            fontSize: "16px"
          }
        },
        colors: [],
        xaxis: {
          type: "datetime",
          labels: {
            format: "MMM"
          }
        },
        yaxis: [
          {
            show: true,
            tickAmount: 5,
            min: 0
          }
        ]
      },
      monthlySeries: []
    };
  },
  watch: {
    select(newVal) {
      if (newVal !== {} && !isNil(this.subject)) {
        this.country = newVal.country;
        this.language = newVal.language;
        this.fetch(this.subject);
      }
    },
    subject(newVal) {
      if (!isNil(this.country) && !isNil(newVal)) {
        this.fetch(newVal);
      }
    }
  },
  methods: {
    async fetch(subjectId) {
      this.loading = true;
      this.visible = false;
      this.monthlySeries = [];
      this.monthlyOptions.colors = [];
      this.options.xaxis.categories = [];
      this.series[0].data = [];
      const now = moment().format("YYYY-MM-DD");
      const oneYearAgo = moment()
        .subtract(1, "years")
        .startOf("month")
        .format("YYYY-MM-DD");

      this.websiteMap = await fetchSubjectMapCall({
        subjects: [subjectId],
        language: this.language,
        country: this.country,
        date: { from: oneYearAgo, to: now }
      });
      this.loading = false;
      this.visible = true;
      let categories = [];
      Object.entries(this.websiteMap).forEach(([key, website]) => {
        this.series[0].data.push(
          sum(website.data.map(data => data.submissionCount))
        );
        categories.push(key);
      });
      this.options = {
        ...this.options,
        ...{
          xaxis: {
            categories: categories
          }
        }
      };
      Object.values(this.websiteMap)
        .slice(0, 5)
        .forEach((value, index) => this.addSeriesToMonthly(index));
    },
    addSeriesToMonthly(index) {
      const host = this.options.xaxis.categories[index];
      const data = this.websiteMap[host].data;
      if (this.monthlySeries.find(serie => serie.name === host)) {
        this.monthlySeries = this.monthlySeries.filter(
          serie => serie.name !== host
        );
        const colors = this.monthlyOptions.colors.filter(
          color => color !== this.options.colors[index]
        );
        this.monthlyOptions = {
          ...this.monthlyOptions,
          ...{
            colors: colors
          }
        };
      } else {
        const neededTimeSeries = this.getPlaceholderTimeSeries(
          moment()
            .subtract(1, "years")
            .startOf("month"),
          moment()
        );
        let dataToAdd = [];
        neededTimeSeries.forEach(date => {
          const timeSerie = data.filter(
            timeSerie => timeSerie.date === date
          )[0];
          if (!isNil(timeSerie)) {
            dataToAdd.push([
              new Date(timeSerie.date).getTime(),
              timeSerie.submissionCount
            ]);
          } else {
            dataToAdd.push([new Date(date).getTime(), 0]);
          }
        });
        this.monthlyOptions.colors.push(this.options.colors[index]);
        this.monthlySeries.push({
          name: host,
          data: dataToAdd
        });
        this.monthlyOptions = {
          ...this.monthlyOptions,
          ...{
            colors: [...new Set(this.monthlyOptions.colors)]
          }
        };
      }
    },
    getPlaceholderTimeSeries(firstDate, secondDate) {
      let emptySeries = [];
      let oneYearAgo = firstDate;
      while (oneYearAgo < secondDate) {
        emptySeries.push(oneYearAgo.format("YYYY-MM"));
        oneYearAgo.add(1, "month");
      }
      return emptySeries;
    }
  }
};
</script>
