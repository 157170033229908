<template>
  <v-form>
    <v-select
      :items="selectableTimeframes"
      label="Date Range"
      v-model="timeframe"
    ></v-select>
    <between-date-input v-model="betweenDates" />
    <v-select-locale v-model="locales" multiple />
    <v-autocomplete-website v-model="websites" multiple />
    <v-autocomplete-subject v-model="subjects" multiple />
    <v-autocomplete-tag
      label="Included article tags"
      v-model="includedArticleTags"
      multiple
      :type="'ARTICLE'"
    />
    <v-autocomplete-tag
      label="Excluded article tags"
      v-model="excludedArticleTags"
      multiple
      :type="'ARTICLE'"
    />
    <v-autocomplete-tag
      label="Included website tags"
      v-model="includedWebsiteTags"
      multiple
      :type="'WEBSITE'"
    />
    <v-autocomplete-tag
      label="Excluded website tags"
      v-model="excludedWebsiteTags"
      multiple
      :type="'WEBSITE'"
    />
  </v-form>
</template>
<script type="text/babel">
import VSelectLocale from "../../locale/VSelectLocale";
import { createNamespacedHelpers } from "@/store/helpers";
import VAutocompleteWebsite from "@/components/website/VAutocompleteWebsite";
import VAutocompleteSubject from "@/components/subject/VAutocompleteSubject";
import VAutocompleteTag from "@/components/tag/VAutocompleteTag";
import BetweenDateInput from "@/components/generic/input/dates/BetweenDateInput";

const { mapComputedFilters } = createNamespacedHelpers(
  "articles-conversions-report"
);

export default {
  name: "articles-conversions-report-table-filters",
  components: {
    VAutocompleteTag,
    BetweenDateInput,
    VAutocompleteSubject,
    VAutocompleteWebsite,
    VSelectLocale
  },
  props: {
    store: {
      type: String,
      required: true
    }
  },
  data: () => ({
    selectableTimeframes: [
      "This month",
      "Last month",
      "Last 30 days",
      "Last 60 days",
      "Last 90 days",
      "Last 180 days",
      "This year",
      "Last year",
      "Custom"
    ]
  }),
  created() {
    let date = new Date();
    this.to = this.formatDate(date);
    this.from = this.formatDate(
      new Date(new Date().setDate(date.getDate() - 90))
    );
  },
  computed: {
    ...mapComputedFilters([
      "locales",
      "websites",
      "subjects",
      "from",
      "to",
      "search",
      "includedArticleTags",
      "excludedArticleTags",
      "includedWebsiteTags",
      "excludedWebsiteTags"
    ]),
    betweenDates: {
      get() {
        return { from: this.from, to: this.to };
      },
      set(dates) {
        this.from = dates.from;
        this.to = dates.to;
        this.timeframe = "Custom";
      }
    },
    timeframe: {
      get() {
        return "Last 90 days";
      },
      set(value) {
        let date = new Date();
        switch (value) {
          case "This month":
            this.from = this.formatDate(
              new Date(date.getFullYear(), date.getMonth(), 1)
            );
            this.to = this.formatDate(date);
            break;
          case "Last month":
            this.from = this.formatDate(
              new Date(date.getFullYear(), date.getMonth() - 1, 1)
            );
            this.to = this.formatDate(date.setDate(0));
            break;
          case "Last 30 days":
            this.to = this.formatDate(date);
            this.from = this.formatDate(
              new Date(new Date().setDate(date.getDate() - 30))
            );
            break;
          case "Last 60 days":
            this.to = this.formatDate(date);
            this.from = this.formatDate(
              new Date(new Date().setDate(date.getDate() - 60))
            );
            break;
          case "Last 90 days":
            this.to = this.formatDate(date);
            this.from = this.formatDate(
              new Date(new Date().setDate(date.getDate() - 90))
            );
            break;
          case "Last 180 days":
            this.to = this.formatDate(date);
            this.from = this.formatDate(
              new Date(new Date().setDate(date.getDate() - 180))
            );
            break;
          case "This year":
            this.from = this.formatDate(
              new Date(new Date().getFullYear(), 0, 1)
            );
            this.to = this.formatDate(date);
            break;
          case "Last year":
            this.from = this.formatDate(
              new Date(new Date().getFullYear() - 1, 0, 1)
            );
            this.to = this.formatDate(
              new Date(new Date().getFullYear() - 1, 12, 0)
            );
            break;
          default:
        }
      }
    }
  },
  watch: {
    search(newVal, oldVal) {
      if (newVal.length > oldVal.length) {
        this.websites = null;
      }
    }
  },
  methods: {
    formatDate(date) {
      let d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    }
  }
};
</script>
