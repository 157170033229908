<template>
  <div v-if="loading" class="text-xs-center">
    <v-progress-circular size="50" color="primary" indeterminate />
  </div>
  <div v-else>
    <v-card style="background-color: white; margin-top: 20px;">
      <monthly-subject-submissions-graph
        title="Total Submissions"
        :total-stats="totalMonthlySubmissions"
        :total-last-year-stats="lastYearTotalMonthlySubmissions"
        :nl-be-stats="beNLMonthlySubmissions"
        :fr-be-stats="beFrMonthlySubmissions"
        :nl-nl-stats="nlNllMonthlySubmissions"
        :fr-fr-stats="frFrlMonthlySubmissions"
        :average-data="averageTotalMonthlySubmissions"
        :last-year-average-data="averageLastYearTotalMonthlySubmissions"
        :visible="visible"
      />
    </v-card>
    <v-layout v-if="visible" row style="margin-top: 20px;">
      <v-flex xs6>
        <v-card>
          <monthly-submissions-graph-locales
            title="BE NL"
            :stats="beNLMonthlySubmissions"
            :last-year-stats="lastYearBeNLMonthlySubmissions"
            :average-data="averageBeNLMonthlySubmissions"
            :last-year-average-data="averageLastYearBeNLMonthlySubmissions"
            :colors="['#FFCD00', '#ffe475']"
          />
        </v-card>
      </v-flex>
      <v-flex xs6>
        <v-card>
          <monthly-submissions-graph-locales
            title="BE FR"
            :stats="beFrMonthlySubmissions"
            :last-year-stats="lastYearBeFrMonthlySubmissions"
            :average-data="averageBeFrMonthlySubmissions"
            :last-year-average-data="averageLastYearBeFrMonthlySubmissions"
            :colors="['#cc0000', '#cc6060']"
          />
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout v-if="visible" row style="margin-top: 20px;">
      <v-flex xs6>
        <v-card>
          <monthly-submissions-graph-locales
            title="NL NL"
            :stats="nlNllMonthlySubmissions"
            :last-year-stats="lastYearNlNllMonthlySubmissions"
            :average-data="averageNlNllMonthlySubmissions"
            :last-year-average-data="averageLastYearNlNllMonthlySubmissions"
            :colors="['#ed9600', '#ebb860']"
          />
        </v-card>
      </v-flex>
      <v-flex xs6>
        <v-card>
          <monthly-submissions-graph-locales
            title="FR FR"
            :stats="frFrlMonthlySubmissions"
            :last-year-stats="lastYearFrFrlMonthlySubmissions"
            :average-data="averageFrFrlMonthlySubmissions"
            :last-year-average-data="averageLastYearFrFrlMonthlySubmissions"
            :colors="['#2E6FB4', '#6da0d6']"
          />
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script type="text/babel">
import moment from "moment";
import api from "@/api";
import { isNil } from "lodash";
import MonthlySubjectSubmissionsGraph from "@/views/statistics/subjects/MonthlySubjectSubmissionsGraph";
import MonthlySubmissionsGraphLocales from "@/views/dashboard/Graphs/Submissions/MonthlySubmissionsGraphLocales";

const fetchMonthlySubmissionsCall = api.submissionsStats.fetchMonthlyTimeSeries;

export default {
  name: "monthly-subject-submissions",
  props: {
    subject: {
      type: Number,
      required: false
    }
  },
  components: {
    MonthlySubmissionsGraphLocales,
    MonthlySubjectSubmissionsGraph
  },
  data() {
    return {
      visible: false,
      loading: false,
      totalMonthlySubmissions: [],
      beNLMonthlySubmissions: [],
      beFrMonthlySubmissions: [],
      nlNllMonthlySubmissions: [],
      frFrlMonthlySubmissions: [],
      lastYearTotalMonthlySubmissions: [],
      lastYearBeNLMonthlySubmissions: [],
      lastYearBeFrMonthlySubmissions: [],
      lastYearNlNllMonthlySubmissions: [],
      lastYearFrFrlMonthlySubmissions: [],
      averageTotalMonthlySubmissions: "",
      averageBeNLMonthlySubmissions: "",
      averageBeFrMonthlySubmissions: "",
      averageNlNllMonthlySubmissions: "",
      averageFrFrlMonthlySubmissions: "",
      averageLastYearTotalMonthlySubmissions: "",
      averageLastYearBeNLMonthlySubmissions: "",
      averageLastYearBeFrMonthlySubmissions: "",
      averageLastYearNlNllMonthlySubmissions: "",
      averageLastYearFrFrlMonthlySubmissions: ""
    };
  },
  watch: {
    subject(newVal) {
      this.fetch(newVal);
    }
  },
  computed: {},
  methods: {
    async fetch(subjectId) {
      this.visible = false;
      this.loading = true;
      const now = moment().format("YYYY-MM-DD");
      const oneYearAgo = moment()
        .subtract(1, "years")
        .startOf("month")
        .format("YYYY-MM-DD");

      const nowOneYearAgo = moment()
        .subtract(1, "years")
        .format("YYYY-MM-DD");
      const twoYearsAgo = moment()
        .subtract(2, "years")
        .startOf("month")
        .format("YYYY-MM-DD");

      const monthlySubmissionTimeSeries = await fetchMonthlySubmissionsCall({
        subjects: [subjectId],
        date: { from: oneYearAgo, to: now }
      });
      this.loading = false;
      this.visible = true;
      const lastYearMonthlySubmissionTimeSeries = await fetchMonthlySubmissionsCall(
        {
          subjects: [subjectId],
          date: { from: twoYearsAgo, to: nowOneYearAgo }
        }
      );
      this.totalMonthlySubmissions = monthlySubmissionTimeSeries["total"].data;
      this.beNLMonthlySubmissions = isNil(monthlySubmissionTimeSeries["nl_be"])
        ? []
        : monthlySubmissionTimeSeries["nl_be"].data;
      this.beFrMonthlySubmissions = isNil(monthlySubmissionTimeSeries["fr_be"])
        ? []
        : monthlySubmissionTimeSeries["fr_be"].data;
      this.nlNllMonthlySubmissions = isNil(monthlySubmissionTimeSeries["nl_nl"])
        ? []
        : monthlySubmissionTimeSeries["nl_nl"].data;
      this.frFrlMonthlySubmissions = isNil(monthlySubmissionTimeSeries["fr_fr"])
        ? []
        : monthlySubmissionTimeSeries["fr_fr"].data;

      this.lastYearTotalMonthlySubmissions =
        lastYearMonthlySubmissionTimeSeries["total"].data;
      this.lastYearBeNLMonthlySubmissions = isNil(
        lastYearMonthlySubmissionTimeSeries["nl_be"]
      )
        ? []
        : lastYearMonthlySubmissionTimeSeries["nl_be"].data;
      this.lastYearBeFrMonthlySubmissions = isNil(
        lastYearMonthlySubmissionTimeSeries["fr_be"]
      )
        ? []
        : lastYearMonthlySubmissionTimeSeries["fr_be"].data;
      this.lastYearNlNllMonthlySubmissions = isNil(
        lastYearMonthlySubmissionTimeSeries["nl_nl"]
      )
        ? []
        : lastYearMonthlySubmissionTimeSeries["nl_nl"].data;
      this.lastYearFrFrlMonthlySubmissions = isNil(
        lastYearMonthlySubmissionTimeSeries["fr_fr"]
      )
        ? []
        : lastYearMonthlySubmissionTimeSeries["fr_fr"].data;

      this.averageTotalMonthlySubmissions = this.getAvg(
        this.lastYearTotalMonthlySubmissions.map(timeframe => timeframe.data)
      );
      this.averageBeNLMonthlySubmissions = this.getAvg(
        this.beNLMonthlySubmissions.map(timeframe => timeframe.data)
      );
      this.averageBeFrMonthlySubmissions = this.getAvg(
        this.beFrMonthlySubmissions.map(timeframe => timeframe.data)
      );
      this.averageNlNllMonthlySubmissions = this.getAvg(
        this.nlNllMonthlySubmissions.map(timeframe => timeframe.data)
      );
      this.averageFrFrlMonthlySubmissions = this.getAvg(
        this.frFrlMonthlySubmissions.map(timeframe => timeframe.data)
      );

      this.averageLastYearTotalMonthlySubmissions = this.getAvg(
        this.lastYearTotalMonthlySubmissions.map(timeframe => timeframe.data)
      );
      this.averageLastYearBeNLMonthlySubmissions = this.getAvg(
        this.lastYearBeNLMonthlySubmissions.map(timeframe => timeframe.data)
      );
      this.averageLastYearBeFrMonthlySubmissions = this.getAvg(
        this.lastYearBeFrMonthlySubmissions.map(timeframe => timeframe.data)
      );
      this.averageLastYearNlNllMonthlySubmissions = this.getAvg(
        this.lastYearNlNllMonthlySubmissions.map(timeframe => timeframe.data)
      );
      this.averageLastYearFrFrlMonthlySubmissions = this.getAvg(
        this.lastYearFrFrlMonthlySubmissions.map(timeframe => timeframe.data)
      );
    },
    getAvg(grades) {
      grades.pop();
      const total = grades.reduce((acc, c) => acc + c, 0);
      return (total / grades.length).toFixed(0);
    }
    // getPlaceholderTimeSeries(firstDate, secondDate) {
    //   let emptySeries = [];
    //   let oneYearAgo = firstDate;
    //   let now = secondDate;
    //
    //   while (oneYearAgo < now) {
    //     emptySeries.push({
    //       monthlySubmissions: null,
    //       date: oneYearAgo.format("YYYY-MM")
    //     });
    //     oneYearAgo.add(1, "month");
    //   }
    //   return emptySeries;
    // }
  }
};
</script>
