<template>
  <v-card v-if="visible">
    <div style="height: 200px">
      <vue-apex-charts
        ref="monthlySubmissionsChart"
        height="100%"
        type="line"
        :options="options"
        :series="series"
      />
    </div>
    <v-layout
      justify-space-between
      style="padding-left: 20px; padding-right: 20px"
    >
      <v-flex xs3>
        Average submissions: <br />
        <b style="font-size: 18px;">{{ averageData }}</b>
      </v-flex>
      <v-flex xs3 style="text-align: end; color: gray;">
        Average last year: <br />
        <b style="font-size: 18px;">{{ lastYearAverageData }}</b>
      </v-flex>
    </v-layout>
  </v-card>
</template>
<script type="text/babel">
import vueApexCharts from "vue-apexcharts";
import { isNil, cloneDeep } from "lodash";
import moment from "moment";

export default {
  name: "monthly-subject-submissions-graph",
  components: {
    vueApexCharts
  },
  props: {
    totalStats: {
      type: Array,
      default: () => []
    },
    totalLastYearStats: {
      type: Array,
      default: () => []
    },
    nlBeStats: {
      type: Array,
      default: () => []
    },
    frBeStats: {
      type: Array,
      default: () => []
    },
    nlNlStats: {
      type: Array,
      default: () => []
    },
    frFrStats: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      required: true
    },
    averageData: {
      type: String,
      required: true
    },
    lastYearAverageData: {
      type: String,
      required: true
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      options: {
        chart: {
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          background: "#f5f5f5"
        },
        stroke: {
          width: 2,
          dashArray: [0, 8]
        },
        title: {
          text: this.title,
          align: "center",
          margin: 30,
          offsetY: 20,
          floating: true,
          style: {
            fontSize: "16px"
          }
        },
        colors: [
          "#000000",
          "#7d7d7d",
          "#FFCD00",
          "#cc0000",
          "#ed9600",
          "#2E6FB4"
        ],
        xaxis: {
          type: "datetime",
          labels: {
            format: "MMM"
          }
        },
        yaxis: [
          {
            show: true,
            tickAmount: 5,
            min: 0
          }
        ]
      },
      series: [
        {
          name: "Total Submissions",
          type: "line",
          data: []
        },
        {
          name: "Total Last year submissions",
          type: "line",
          data: []
        },
        {
          name: "BE NL",
          type: "line",
          data: []
        },
        {
          name: "BE FR",
          type: "line",
          data: []
        },
        {
          name: "NL NL",
          type: "line",
          data: []
        },
        {
          name: "FR FR",
          type: "line",
          data: []
        }
      ]
    };
  },
  watch: {
    visible(newVal) {
      if (!newVal) {
        this.series[0].data = [];
        this.series[1].data = [];
        this.series[2].data = [];
        this.series[3].data = [];
        this.series[4].data = [];
        this.series[5].data = [];
      }
    },
    totalStats: {
      handler(newVal) {
        const neededTimeSeries = this.getPlaceholderTimeSeries(
          moment()
            .subtract(1, "years")
            .startOf("month"),
          moment()
        );
        this.series[0].data = [];
        neededTimeSeries.forEach(date => {
          const timeSerie = newVal.filter(
            timeSerie => timeSerie.date === date
          )[0];
          if (!isNil(timeSerie)) {
            this.series[0].data.push([
              new Date(timeSerie.date).getTime(),
              timeSerie.data
            ]);
          } else {
            this.series[0].data.push([new Date(date).getTime(), 0]);
          }
        });
        this.$refs.monthlySubmissionsChart.refresh();
      },
      deep: true
    },
    nlBeStats: {
      handler(newVal) {
        const neededTimeSeries = this.getPlaceholderTimeSeries(
          moment()
            .subtract(1, "years")
            .startOf("month"),
          moment()
        );
        this.series[2].data = [];
        neededTimeSeries.forEach(date => {
          const timeSerie = newVal.filter(
            timeSerie => timeSerie.date === date
          )[0];
          if (!isNil(timeSerie)) {
            this.series[2].data.push([
              new Date(timeSerie.date).getTime(),
              timeSerie.data
            ]);
          } else {
            this.series[2].data.push([new Date(date).getTime(), 0]);
          }
        });
        this.$refs.monthlySubmissionsChart.refresh();
      },
      deep: true
    },
    frBeStats: {
      handler(newVal) {
        const neededTimeSeries = this.getPlaceholderTimeSeries(
          moment()
            .subtract(1, "years")
            .startOf("month"),
          moment()
        );
        this.series[3].data = [];
        neededTimeSeries.forEach(date => {
          const timeSerie = newVal.filter(
            timeSerie => timeSerie.date === date
          )[0];
          if (!isNil(timeSerie)) {
            this.series[3].data.push([
              new Date(timeSerie.date).getTime(),
              timeSerie.data
            ]);
          } else {
            this.series[3].data.push([new Date(date).getTime(), 0]);
          }
        });
        this.$refs.monthlySubmissionsChart.refresh();
      },
      deep: true
    },
    nlNlStats: {
      handler(newVal) {
        const neededTimeSeries = this.getPlaceholderTimeSeries(
          moment()
            .subtract(1, "years")
            .startOf("month"),
          moment()
        );
        this.series[4].data = [];
        neededTimeSeries.forEach(date => {
          const timeSerie = newVal.filter(
            timeSerie => timeSerie.date === date
          )[0];
          if (!isNil(timeSerie)) {
            this.series[4].data.push([
              new Date(timeSerie.date).getTime(),
              timeSerie.data
            ]);
          } else {
            this.series[4].data.push([new Date(date).getTime(), 0]);
          }
        });
        this.$refs.monthlySubmissionsChart.refresh();
      },
      deep: true
    },
    frFrStats: {
      handler(newVal) {
        const neededTimeSeries = this.getPlaceholderTimeSeries(
          moment()
            .subtract(1, "years")
            .startOf("month"),
          moment()
        );
        this.series[5].data = [];
        neededTimeSeries.forEach(date => {
          const timeSerie = newVal.filter(
            timeSerie => timeSerie.date === date
          )[0];
          if (!isNil(timeSerie)) {
            this.series[5].data.push([
              new Date(timeSerie.date).getTime(),
              timeSerie.data
            ]);
          } else {
            this.series[5].data.push([new Date(date).getTime(), 0]);
          }
        });
        this.$refs.monthlySubmissionsChart.refresh();
      },
      deep: true
    },
    totalLastYearStats: {
      handler(newVal) {
        let mappedVal = cloneDeep(newVal);
        mappedVal = mappedVal.map(data => {
          data.date = moment(data.date)
            .add(1, "years")
            .format("YYYY-MM");
          return data;
        });
        this.series[1].data = [];

        this.series[0].data.forEach(data => {
          const equivalentLastYear = mappedVal.filter(lastYear => {
            return data[0] === new Date(lastYear.date).getTime();
          })[0];
          if (!isNil(equivalentLastYear)) {
            this.series[1].data.push([
              new Date(equivalentLastYear.date).getTime(),
              equivalentLastYear.data
            ]);
          } else {
            this.series[1].data.push([data[0], 0.0]);
          }
        });
        this.$refs.monthlySubmissionsChart.refresh();
        setTimeout(this.disableLegends, 500);
      },
      deep: true
    }
  },
  computed: {},
  methods: {
    round(rate) {
      return !isNil(rate) ? rate.toFixed(2) : rate;
    },
    disableLegends() {
      const chart = this.$refs.monthlySubmissionsChart;
      if (!isNil(chart)) {
        chart.toggleSeries("BE NL");
        chart.toggleSeries("BE FR");
        chart.toggleSeries("FR FR");
        chart.toggleSeries("NL NL");
      }
      chart.refresh();
    },
    getPlaceholderTimeSeries(firstDate, secondDate) {
      let emptySeries = [];
      let oneYearAgo = firstDate;
      while (oneYearAgo < secondDate) {
        emptySeries.push(oneYearAgo.format("YYYY-MM"));
        oneYearAgo.add(1, "month");
      }
      return emptySeries;
    }
  }
};
</script>
