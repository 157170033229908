<template>
  <v-card>
    <div style="height: 200px">
      <vue-apex-charts
        ref="monthlySubmissionsChart"
        height="100%"
        type="line"
        :options="options"
        :series="series"
      />
    </div>
    <v-layout
      justify-space-between
      style="padding-left: 20px; padding-right: 20px"
    >
      <v-flex xs3>
        Total submissions: <br />
        Total: <b style="font-size: 18px;">{{ totalSubmissions }}</b
        ><br />
        Average: <b style="font-size: 18px;">{{ averageSubmissions }}</b>
      </v-flex>
      <v-flex xs3 style="text-align: end;">
        Sold submissions: <br />
        Total: <b style="font-size: 18px;">{{ totalSoldSubmissions }}</b
        ><br />
        Average: <b style="font-size: 18px;">{{ averageSoldSubmissions }}</b>
      </v-flex>
    </v-layout>
  </v-card>
</template>
<script type="text/babel">
import vueApexCharts from "vue-apexcharts";

export default {
  name: "subject-sales-graph",
  components: {
    vueApexCharts
  },
  props: {
    salesData: {
      type: Array,
      default: () => []
    },
    subjectName: {
      type: String,
      default: ""
    }
  },
  data: () => {
    return {
      averageSubmissions: null,
      totalSubmissions: null,
      averageSoldSubmissions: null,
      totalSoldSubmissions: null,
      series: [
        {
          name: "Total Submissions",
          type: "bar",
          data: []
        },
        {
          name: "Sold Submissions",
          type: "bar",
          data: []
        }
      ]
    };
  },
  computed: {
    options() {
      return {
        chart: {
          id: "ssg",
          toolbar: {
            show: false
          },
          background: "#f5f5f5",
          animations: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function(val) {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"]
          }
        },
        grid: {
          show: false
        },
        stroke: {
          width: 2,
          curve: "smooth"
        },
        title: {
          text: this.subjectName,
          align: "center",
          margin: 10,
          offsetY: 20,
          floating: true,
          style: {
            fontSize: "16px",
            color: "#263238"
          }
        },
        colors: ["#FFCD00", "#2E6FB4"],
        xaxis: {
          type: "datetime"
        },
        yaxis: [
          {
            show: false,
            title: {
              text: "Conversions"
            },
            labels: {
              formatter: function(value) {
                return value;
              },
              minWidth: 0
            }
          }
        ]
      };
    }
  },
  watch: {
    salesData: {
      handler(newVal) {
        newVal.forEach(e => {
          this.series[0].data.push([
            new Date(e.date).getTime(),
            e.submissionCount
          ]);
          this.series[1].data.push([
            new Date(e.date).getTime(),
            e.SubmissionsSold
          ]);
        });

        this.totalSubmissions = this.getTotal(
          newVal.map(e => e.submissionCount)
        );
        this.averageSubmissions = this.getAvg(
          newVal.map(e => e.submissionCount)
        );

        this.totalSoldSubmissions = this.getTotal(
          newVal.map(e => e.SubmissionsSold)
        );
        this.averageSoldSubmissions = this.getAvg(
          newVal.map(e => e.SubmissionsSold)
        );
      },
      immediate: true
    }
  },
  methods: {
    getAvg(grades) {
      grades.pop();
      const total = grades.reduce((acc, c) => acc + c, 0);
      return (total / grades.length).toFixed(0);
    },
    getTotal(grades) {
      return grades.reduce((acc, c) => acc + c, 0);
    }
  }
};
</script>
