<template>
  <v-card>
    <v-toolbar color="primary">
      <v-toolbar-title class="white--text"
        >Subject
        <v-chip v-if="subject !== null" color="accent" label>{{
          subjectName
        }}</v-chip>
        Performance</v-toolbar-title
      >
    </v-toolbar>
    <v-container>
      <v-layout justify-center>
        <v-flex xs4>
          <v-autocomplete-subject v-model="subject" />
        </v-flex>
      </v-layout>
      <monthly-subject-submissions :subject="subject" />
      <v-layout
        v-if="subject !== null"
        justify-center
        style="margin-top: 20px;"
      >
        <v-flex xs2>
          <v-select
            v-model="select"
            :items="items"
            item-text="label"
            item-value="value"
            label="Locale"
            single-line
          ></v-select>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs4>
          <subject-exclusive-interest :subject="subject" :select="select" />
        </v-flex>
        <v-flex xs8>
          <subject-interest-map :subject="subject" :select="select" />
        </v-flex>
      </v-layout>
      <subject-website-map :subject="subject" :select="select" />
      <subject-article-map
        style="margin-top: 20px;"
        :subject="subject"
        :select="select"
      />
    </v-container>
  </v-card>
</template>
<script type="text/babel">
import VAutocompleteSubject from "@/components/subject/VAutocompleteSubject";
import MonthlySubjectSubmissions from "@/views/statistics/subjects/MonthlySubjectSubmissions";
import SubjectInterestMap from "@/views/statistics/subjects/SubjectInterestMap";
import SubjectExclusiveInterest from "./SubjectExclusiveInterestChart";
import SubjectWebsiteMap from "@/views/statistics/subjects/SubjectWebsiteMap";
import SubjectArticleMap from "@/views/statistics/subjects/SubjectArticleMap";
export default {
  name: "subject-performance-report-view",
  components: {
    SubjectArticleMap,
    SubjectWebsiteMap,
    SubjectExclusiveInterest,
    SubjectInterestMap,
    MonthlySubjectSubmissions,
    VAutocompleteSubject
  },
  data: () => {
    return {
      subject: null,
      select: {},
      items: [
        { label: "Belgie", value: { country: "Belgium", language: null } },
        {
          label: "Nederland",
          value: { country: "Netherlands", language: null }
        },
        { label: "Frankrijk", value: { country: "France", language: null } },
        {
          label: "Belgie NL",
          value: { country: "Belgium", language: "Dutch" }
        },
        {
          label: "Belgie FR",
          value: { country: "Belgium", language: "French" }
        }
      ]
    };
  },
  computed: {
    subjectName() {
      return this.$store.getters["subjects-select/subjectName"](this.subject);
    }
  }
};
</script>
<style lang="scss"></style>
