<template>
  <div>
    <subject-sales-performance-report-filters @fetch="fetch" />
    <subject-sales-graph
      class="ma-3"
      v-for="(serie, index) in series"
      :key="index"
      :sales-data="serie"
      :subject-name="subjectNames[index]"
    />
  </div>
</template>
<script type="text/babel">
import api from "@/api";
import SubjectSalesGraph from "./SubjectSalesGraph";
import SubjectSalesPerformanceReportFilters from "./SubjectSalesPerformanceReportFilters";
const fetchSubjectsSalesReportCall = api.subjectsStats.fetchSalesReportMap;
export default {
  name: "subject-sales-performance-report-view",
  components: {
    SubjectSalesPerformanceReportFilters,
    SubjectSalesGraph
  },
  data() {
    return {
      series: [],
      subjectNames: []
    };
  },
  created() {},
  computed: {},
  methods: {
    async fetch(filters) {
      this.series = [];
      this.subjectNames = [];
      const data = await fetchSubjectsSalesReportCall(filters);
      Object.keys(data).forEach(key => {
        this.series.push(data[key].data);
        this.subjectNames.push(data[key].data[0].subjectName);
      });
    }
  }
};
</script>
